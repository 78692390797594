import React, {MouseEventHandler} from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import GabIcon from "../gab-icon";

interface propsCardForm {
    icon:any;
    size?:any;
    height?:any;
    color?:string;
    textColor?:string;
    themeColor?: "primary" | "secondary";
    onClick?: MouseEventHandler;
    disabled?:boolean;
    tip?:string;
}
const GABButtonIcon: React.FC<propsCardForm> = (props) => {
    const theme = useTheme();
    const pallertColor = props.themeColor === "primary"? theme.palette.primary: theme.palette.secondary;

    return (
        <Tooltip title={props.tip}>
            <IconButton
                disabled={props?.disabled}
                onClick={props.onClick}
                style={{
                backgroundColor : `${props.color || pallertColor.main}`,
                margin : '0.02rem'
            }}>
                <GabIcon width={props.size} height={props.size} icon={props.icon} color={`${props.textColor || pallertColor.contrastText}`}/>
            </IconButton>
        </Tooltip>
    );
};

export default GABButtonIcon;
