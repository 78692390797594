import React from "react";
import {Chip, Grid, Stack, Typography} from "@mui/material";
import GABChip from "../gab-chip";
import GABLabel from "../gab-label";

interface propsGABChipList {
  title:string;
  label:string;
  list:any[];
    size?: number;
}

const GABChipList: React.FC<propsGABChipList> = props => {
  return (
      <Grid item container xs={12}  md={props?.size || 12} spacing={0} padding={1}>
          <Grid item xs={12}>
              <GABLabel title={props.title}/>
          </Grid>
          <Grid item xs={12}>
              <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        {
          props.list.map((i:any) => <GABChip label={i[props.label]}/> )
        }
              </Stack>
      </Grid>
      </Grid>
  );
};

export default GABChipList;
