import {
    Divider,
    Fade, Grid, Typography,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import GABModal from "../../../components/gab-modal";
import AppProviderContext, {propsAppProviderContext} from "../../../app-provider";
import GABLabel from "../../../components/gab-label";
import {formatCpf, getAge} from "../../../utils/str-utils";
import {Demanda, DemandaUtils, Tarefa} from "../dto/demanda";
import GABForm from "../../../components/gab-form";
import {useTheme} from "@mui/material/styles";
import GABChip from "../../../components/gab-chip";
import GABChipList from "../../../components/gab-chip-list";
import {format} from "date-fns";
import GabChipStatusDemanda from "../list/component/gab-chip-status-demanda";
import {STATUS_GERAL} from "../../../utils/select-utils.domain";
import GabInput from "../../../components/gab-input";
import GABButtonIcon from "../../../components/gab-button-icon";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";


interface propsDemandaDetail {
    idDemanda?: string;
    isOpen: boolean;
    onClose: () => void;
}

const DemandaDetail: React.FC<propsDemandaDetail> = (props) => {
    const {
        getAxios,
        postAxios,
        setLoading,
        addMessage,
        profile,
        canAccess
    }: propsAppProviderContext = useContext(AppProviderContext);
    const [demanda, setDemanda] = useState<Demanda>()
    const formik = useFormik({
        initialValues: DemandaUtils.createNewDemanda(),
        validationSchema: DemandaUtils.validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const resp = await postAxios('/parlamentar', formik.values)
            formik.resetForm();
            addMessage(`Demanda foi salva com sucesso ${resp.data?.protocolo}`, "success")
            setLoading(false);
            setDemanda(undefined)

        },
    });
    const init = async () => {
        if (props?.idDemanda) {
            setLoading(true)
            const response = await getAxios(`parlamentar/demanda/id/${props.idDemanda}`)
            let clone: any = Object.assign({}, response.data.demanda[0]);
            if (clone._id) {
                clone.id = response.data.demanda[0]._id;
                delete clone._id;
            }
            setDemanda(clone);
            setLoading(false);
            await formik.setValues(clone)
        }
    }

    useEffect(
        () => {
            init();
        }, [props?.idDemanda]
    )
    const navigator = useNavigate();

    const theme = useTheme();

    const isResponsavel = () =>{
        return demanda?.responsaveis ? demanda?.responsaveis.findIndex((e) => e.value === profile._id) >= 0 : false;
    }

    return (
        <Fade in timeout={1000}>
            <div>
                <GABModal

                    maxWidth={'60%'}
                    title={`Demanda # ${demanda?.protocolo.split('-')[1]}`}
                    open={props.isOpen}
                    onClickMiddle={canAccess([9]) || isResponsavel() ? ()=>{
                        navigator(`/admin/demanda/edit/${demanda?.id}`)
                    } : undefined}
                    labelMiddle={canAccess([9]) || isResponsavel() ? 'Editar' : undefined}

                    onClickRight={
                        async () => {
                            props.onClose();
                            formik.submitForm();
                        }
                    }
                    onClickLeft={
                        async () => {
                            props.onClose();

                        }
                    }
                    labelRight={"Salvar"}
                    labelLeft={"Fechar"}
                >
                    <GABForm
                        icon={"streamline:interface-file-clipboard-text-edition-form-task-checklist-edit-clipboard"}
                        title={demanda?.pessoa[0]?.nome}
                        subtile={formatCpf(demanda?.pessoa[0]?.cpfCnpj) || ''}
                    >
                        <Grid container spacing={0} padding={0}>

                            {
                                demanda?.interna === false?
                                    <>
                                        <GABLabel size={2} colorBadge={"secondary"}
                                                  title={'Nascimento'}
                                                  subTitle={demanda?.pessoa[0]?.nascimento}
                                                  subTitleBadge={getAge(demanda?.pessoa[0]?.nascimento)}/>

                                        <GABLabel size={2} title={'Logradouro'}
                                                  subTitle={demanda?.pessoa[0]?.endereco?.logradouro}/>
                                        <GABLabel size={2} title={'Bairro'} subTitle={demanda?.pessoa[0]?.endereco?.bairro}/>
                                        <GABLabel size={2} title={'Cidade'} subTitle={demanda?.pessoa[0]?.endereco?.cidade}/>
                                        <GABLabel size={1}   title={'UF'} subTitle={demanda?.pessoa[0]?.endereco?.uf}/>

                                        <GABLabel size={2}  sizeMobile={12} title={'Nome'} subTitle={demanda?.pessoa[0]?.listaTelefone[0]?.nome}/>
                                        <GABLabel size={2}  sizeMobile={12} title={'Fone'} subTitle={demanda?.pessoa[0]?.listaTelefone[0]?.fone}/>
                                        <GABLabel size={2}  sizeMobile={12} title={'Nome'} subTitle={demanda?.pessoa[0]?.listaTelefone[1]?.nome}/>
                                        <GABLabel size={2}  sizeMobile={12} title={'Fone'} subTitle={demanda?.pessoa[0]?.listaTelefone[1]?.fone}/>
                                        <Grid item xs={12}>

                                            <div
                                                style={{
                                                    backgroundColor: `${theme.palette.grey[200]}`,
                                                    padding: '1rem',
                                                    height: '15rem',
                                                    maxHeight: '15rem',
                                                    overflowY: 'auto',
                                                    margin: '0.rem'
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        demanda?.descricao || ''
                                                }}
                                            />
                                        </Grid>
                                    </> :
                        <>
                            <GABLabel size={12} title={'Titulo'} subTitle={demanda?.titulo}/>
                            <Grid item xs={12}>

                                <div
                                    style={{
                                        backgroundColor: `${theme.palette.grey[200]}`,
                                        padding: '1rem',
                                        height: '15rem',
                                        maxHeight: '15rem',
                                        overflowY: 'auto',
                                        margin: '0.rem'
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            demanda?.descricao || ''
                                    }}
                                />
                            </Grid>
                        </>
                            }


                            <GABChipList size={3} title={'Equipe'} label={'nome'} list={demanda?.caixa || []}/>
                            <GABChipList size={3} title={'Categoria'} label={'nome'} list={demanda?.categorias || []}/>
                            <GABChipList size={3} title={'Região'} label={'label'} list={demanda?.regiao || []}/>
                            <GABChipList size={3} title={'Responsáveis'} label={'label'}list={demanda?.responsaveis || []}/>
                            <GABLabel size={3} title={'Data'} subTitle={demanda?.prazoData}/>
                            <GABLabel size={3} title={'Prioridade'} subTitle={demanda?.prioridade}/>

                            <GabInput
                                label={"Status Geral"}
                                id={"statusGeral"}
                                value={formik.values?.statusGeral?.value}
                                onChange={(e: any) => {
                                    const statusG = STATUS_GERAL.filter((s) => s.value === e.target.value);
                                    formik.setFieldValue('statusGeral', statusG[0])
                                }}
                                size={3}
                            >
                                {STATUS_GERAL.map((option) => (
                                    <option key={option?.value} value={option?.value}>
                                        {option?.label}
                                    </option>
                                ))}
                            </GabInput>
                        </Grid>
                    </GABForm>

                    <GABForm
                        icon={"jam:task-list"}
                        title={"Tarefas"}
                        subtile={`Tarefas da demanda`}>
                        <Grid container spacing={0} padding={0}>
                            {
                                demanda?.tarefas?.map((t:Tarefa, index:number) =>
                                    <Grid container spacing={0} padding={0}
                                          style={{
                                              backgroundColor: `${t?.status === 1 ? theme.palette.success.light : ''}`
                                          }}
                                    >
                                        <Grid item container xs={6}>
                                            <Grid item xs={1} style={{
                                                paddingTop: '0.8rem',
                                                paddingLeft: '0.8rem',
                                            }}>
                                                {
                                                    (t.responsavel?.value === profile._id) ?
                                                        <GABButtonIcon
                                                            onClick={ async ()=>{
                                                                let terefas = formik.values.tarefas;
                                                                terefas[index].status =1;
                                                                await formik.setFieldValue('tarefas', terefas);
                                                                setDemanda(formik.values)

                                                            }}
                                                            color={`${t?.status === 1 ? theme.palette.grey["50"] : ''}`}
                                                            textColor={`${t?.status === 1 ? theme.palette.grey["400"] : ''}`}
                                                            tip={'Confirmar tarefa'}
                                                            disabled={t?.status === 1 || false}
                                                            icon={'akar-icons:circle-check-fill'}/>
                                                        : <></>
                                                }

                                            </Grid>
                                            <GABLabel size={11} title={t.responsavel?.label}
                                                      subTitle={t.responsavel?.email}/>
                                        </Grid>


                                        <GABLabel size={3} title={'Prazo'} subTitle={t.prazo}/>
                                        <GABLabel size={3} title={'Status'}
                                                  subTitle={(t?.status === 1 ? 'Concluído' : 'Aberto') || 'Aberto'}/>

                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    backgroundColor: `${theme.palette.grey[200]}`,
                                                    padding: '1rem',
                                                    height: '5rem',
                                                    maxHeight: '5rem',
                                                    overflowY: 'auto',
                                                    margin: '0.5rem'
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        t?.descricao || ''
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                )
                            }

                        </Grid>
                    </GABForm>

                    <GABForm
                        icon={"akar-icons:attach"}
                        title={"Anexos"}
                        subtile={`Anexar arquivos à demanda`}
                    >
                        <Grid container spacing={0} padding={0}>
                            {
                                demanda?.anexos?.map(
                                    (f) =>
                                        <Grid container spacing={0} padding={0}>
                                            <div style={{
                                                paddingTop: '1rem'
                                            }}>
                                                <GABButtonIcon
                                                    onClick={() => {
                                                        window.open(`http://134.209.78.93:8080/${f.caminho}`, '_new')

                                                    }}
                                                    icon={'ion:open-outline'}/>
                                            </div>
                                            <GABLabel size={8} title={'Arquivo'} subTitle={f.nome}/>
                                        </Grid>
                                )
                            }
                        </Grid>
                    </GABForm>

                    <GABForm
                        icon={"material-symbols:task-alt"}
                        title={"Atividades"}
                        subtile={`Registro de atividades do usuário`}
                    >
                        <Grid container spacing={0} padding={0}>
                            {
                                demanda?.atividades?.map((t) =>
                                    <Grid container spacing={2} padding={2}>
                                        <GABLabel size={6} title={t.responsavel_nome} subTitle={t.responsavel_email}/>
                                        <GABLabel size={6} title={'Prazo'} subTitle={t.data}/>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    backgroundColor: `${theme.palette.grey[200]}`,
                                                    padding: '1rem',
                                                    height: '5rem',
                                                    maxHeight: '5rem',
                                                    overflowY: 'auto',
                                                    margin: '0rem'
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        t?.descricao || ''
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                )
                            }

                        </Grid>
                    </GABForm>
                </GABModal>
            </div>
        </Fade>
    );
};
export default DemandaDetail;
