import {
    Button,
    ButtonGroup,
    Fade,
    Grid,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef, EffectCallback, DependencyList } from "react";
import AppProviderContext, {
    propsAppProviderContext,
} from "../../app-provider";
import {
    listaCamposDemandas,
} from "../../utils/select-utils.domain";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import GABForm from "../../components/gab-form";

import GabInput from "../../components/gab-input/index";

import { green } from "@mui/material/colors";

import GABAutocomplete from "../../components/gab-autocomplete";
import { Caixa, Categoria, Demanda, Regiao, Usuarios } from "../demanda/component/typings";
import GABFormButtons from "../../components/gab-form-buttons";
import GABModal from "../../components/gab-modal";
import GABDataTable from "../../components/gab-data-table";
import { Solicitante } from "../demanda/dto/solicitante";

const DemandaExport: React.FC = () => {
    const { postAxios, setLoading, getAxios }: propsAppProviderContext = useContext(AppProviderContext);
    const [data, setData] = useState<Demanda[]>([]);
    const [modalSelectOpen, setModalSelectOpen] = useState(false);
    const [solicitantes, setSolicitantes] = useState<Solicitante[]>([]);
    const [campos, setCampos] = useState([]);

    const [equipes, setEquipes] = useState<Caixa[]>([])
    const [categoria, setCategoria] = useState<Categoria[]>([])
    const [regiao, setRegiao] = useState<Regiao[]>([])
    const [usuarios, setUsuarios] = useState<Usuarios[]>([])

    const updateCategoria = async () => {
        const request = await getAxios("parlamentar/demanda/categoria");
        setCategoria([{_id: "", nome: ""}, ...request.data.categoria]);
    }
    const updateEquipe = async () => {
        const request = await getAxios("parlamentar/demanda/caixa");
        setEquipes([{_id: "", nome: ""}, ...request.data.caixa]);
    }
    const updateRegiao = async () => {
        const request = await getAxios("parlamentar/regiao");
        setRegiao([{_id: "", nome: ""}, ...request.data.regiao]);
    }
    const updateUsuario = async () => {
        const request = await getAxios("auth/usuario");
        setUsuarios([{_id: "", nome: ""}, ...request.data]);
    }

    const init = async () => {
        setLoading(true);
        await updateEquipe()
        await updateCategoria();
        await updateRegiao();
        await updateUsuario();
        setLoading(false);
    }
    useEffect(() => { init() }, [])

    const exportar = async () => {
        setLoading(true);

        var dados = {
            idPessoa: formik.values.idPessoa,
            categoria: formik.values.cat,
            caixa: formik.values.caixa,
            "statusGeral.value": formik.values.statusGeral,
            "responsaveis.value": formik.values.responsaveis,
            regiao: formik.values.regiao,
            prazoData: formik.values.prazoData
        };


        await postAxios(`parlamentar/exportarDemandas`, { dados: dados, campos: campos, notIn: [] });

        var token = await localStorage.getItem("PARLAMENTAR.APP_TOKEN");

        window.open(
            "https://www.gabinetevirtual.com/api/v1/parlamentar/exportacaoDownload/" +
            encodeURIComponent(token ? token : ""),
            "_blank"
        );

        setLoading(false);
    };

    const columns = [
        {
            name: "protocolo",
            label: "Protocolo",
        },
        {
            name: "descricao",
            label: "Demanda",
        },
        {
            name: 'statusGeral',
            label: "Status",
        }
    ];

    const theme = useTheme();

    const options = {
        search: true,
        download: false,
        print: false,
        selectableRows: "none",
        viewColumns: false,
        responsive: 'vertical',
        customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
            let styleLocal: React.CSSProperties = {};
            if (rowIndex % 2 === 0) {
                styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
            }
            return (
                <TableRow style={styleLocal}>
                    <TableCell>
                        <Typography>{data[0]}</Typography>
                    </TableCell>
                    <TableCell>
                        <div style={{maxHeight: "50px", overflow: "hidden"}} dangerouslySetInnerHTML={{ __html: data[1] }}></div>
                    </TableCell>
                    <TableCell>
                        {data[2]?.label}
                    </TableCell>
                </TableRow>
            )
        }
    } as any;

    const formik = useFormik({
        initialValues: {
            cat: "",
            caixa: "",
            statusGeral: "",
            responsaveis: "",
            prazoData: "",
            protocolo: "",
            demanda: "",
            titulo: "",
            idPessoa: "",
            regiao: "",
        },
        onSubmit: async (values) => {

            setLoading(true);
            var filtro = {
                idPessoa: values.idPessoa,
                categoria: values.cat,
                caixa: values.caixa,
                "statusGeral.value": values.statusGeral,
                "responsaveis.value": values.responsaveis,
                prazoData: values.prazoData,
                protocolo: values.protocolo,
                descricao: values.demanda,
                titulo: values.titulo,
                regiao: values.regiao
            };

            const r = await postAxios(`/parlamentar/demanda_lista`, filtro);

            setData(r.data.demanda);
            setLoading(false);

            window.scrollTo({
                top: 500,
                left: 0,
                behavior: "smooth",
            });

            return false;
        }
    });

    const statusGeral = [
        {
            "label": "",
            "value": ""
        },
        {
            "label": "ABERTA",
            "value": "1"
        },
        {
            "label": "EM ANDAMENTO",
            "value": "2"
        },
        {
            "label": "CANCELADA",
            "value": "3"
        },
        {
            "label": "FINALIZADA",
            "value": "4"
        },
    ]

    const formikSolicitante = useFormik({
        initialValues: {nome: ''},
        onSubmit: async (values) => {
        },
    });

    const pesquisar = async (value: string) => {
        if (value.length > 2) {
            const response = await getAxios(`/parlamentar/pessoa/autocomplete/${value}`)
            setSolicitantes(response.data);
        }
    }

    const prepareSubmit =async (solicitanteSelected:Solicitante)=>{
        let tmp = formik.values;
        tmp.idPessoa =  solicitanteSelected?._id;
        await formik.setValues(tmp)
        let tmp2 = formikSolicitante.values;
        tmp2.nome = solicitanteSelected.nome;
        await formikSolicitante.setValues(tmp2)
    }

    return (
        <Fade in timeout={1000}>
            <div>

                <form onSubmit={formik.handleSubmit}>
                    <GABForm
                        icon={"fluent:task-list-square-person-20-regular"}
                        title={"Exportar Demandas"}
                        subtile={"Busca de demandas para exportação"}
                    >
                        <Grid item xs={8}>
                            <Button
                                fullWidth
                                variant="outlined"
                                type="button"
                                onClick={()=>{
                                    setModalSelectOpen(true);
                                }}
                                style={{height: "100%"}}
                            >
                                {formikSolicitante.values.nome !== "" ? formikSolicitante.values.nome : "Selecionar Solicitante"}
                            </Button>
                        </Grid>

                        <GabInput
                            label={"Data"}
                            id={"prazoData"}
                            size={4}
                            value={formik.values.prazoData}
                            formik={formik}
                            mask="date"
                        />
                        <GabInput
                            label={"Equipe"}
                            id={"caixa"}
                            size={4}
                            value={formik.values.caixa}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.caixa &&
                                Boolean(formik.errors.caixa)
                            }
                        >
                            {equipes.map((option) => (
                                <option key={option?._id} value={option?._id}>
                                    {option?.nome}
                                </option>
                            ))}
                        </GabInput>

                        <GabInput
                            label={"Tema"}
                            id={"cat"}
                            size={4}
                            value={formik.values.cat}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.cat &&
                                Boolean(formik.errors.cat)
                            }
                        >
                            {categoria.map((option) => (
                                <option key={option?._id} value={option?._id}>
                                    {option?.nome}
                                </option>
                            ))}
                        </GabInput>

                        <GabInput
                            label={"Regiao"}
                            id={"regiao"}
                            size={4}
                            value={formik.values.regiao}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.regiao &&
                                Boolean(formik.errors.regiao)
                            }
                        >
                            {regiao.map((option) => (
                                <option key={option?._id} value={option?._id}>
                                    {option?.nome}
                                </option>
                            ))}
                        </GabInput>

                        <GabInput
                            label={"Responsável"}
                            id={"responsaveis"}
                            size={8}
                            value={formik.values.responsaveis}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.responsaveis &&
                                Boolean(formik.errors.responsaveis)
                            }
                        >
                            {usuarios.map((option) => (
                                <option key={option?._id} value={option?._id}>
                                    {option?.nome}
                                </option>
                            ))}
                        </GabInput>

                        <GabInput
                            label={"Status"}
                            id={"statusGeral"}
                            size={4}
                            value={formik.values.statusGeral}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.statusGeral &&
                                Boolean(formik.errors.statusGeral)
                            }
                        >
                            {statusGeral.map((option) => (
                                <option key={option?.value} value={option?.value}>
                                    {option?.label}
                                </option>
                            ))}
                        </GabInput>

                        <GabInput
                            label={"Descrição"}
                            id={"demanda"}
                            size={6}
                            value={formik.values.demanda}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.demanda &&
                                Boolean(formik.errors.demanda)
                            }
                        />
                        <GabInput
                            label={"Título"}
                            id={"titulo"}
                            size={6}
                            value={formik.values.titulo}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.titulo &&
                                Boolean(formik.errors.titulo)
                            }
                        />

                        <GABFormButtons size={12} formik={formik} labelSubmit={'Previsualizar'}/>

                        <Grid item xs={12}>
                            <GABForm
                                icon={"noto:busts-in-silhouette"}
                                title={"Dados a exportar"}
                                subtile={"selecione os campos que deverão ser adicionados a lista exportada"}
                            >
                                <GABAutocomplete
                                    id={"demandaExport"}
                                    size={12}
                                    label={'campos'}
                                    limitTags={-1}
                                    optionLabel={'label'}
                                    value={campos}
                                    options={listaCamposDemandas}
                                    onChange={(event: any, valor: any) => {
                                        setCampos(valor);
                                    }}
                                />
                            </GABForm>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup
                                variant="contained"
                                style={{ width: "100%", padding: "10px", margin: '0px', minHeight: '5rem' }}
                            >
                                <Button
                                    id="btSave"
                                    color="primary"
                                    type="button"
                                    style={{ width: "100%" }}
                                    disabled={campos.length === 0}
                                    onClick={exportar}
                                >
                                    Exportar
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ height: "20px" }} />
                            <MUIDataTable
                                title={`Prévia das demandas a exportar (${data.length} Demandas)`}
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </Grid>
                    </GABForm>
                    <GABModal
                    title={'Buscar Pessoa'}
                    open={modalSelectOpen}
                    onClickRight={
                        async () => {
                            setModalSelectOpen(false);
                        }
                    }
                    onClickLeft={
                        async () => {
                            setModalSelectOpen(false);
                            prepareSubmit(new Solicitante());
                        }
                    }
                    labelRight={"Fechar"}
                    labelLeft={"Limpar"}
                >
                    <form onSubmit={formikSolicitante.handleSubmit}>
                        <Grid container spacing={3}>
                            <GabInput
                                onChange={(e: any) => {
                                    formikSolicitante.setValues(
                                        {
                                            nome: e.target.value
                                        }
                                    )
                                    pesquisar(e.target.value)
                                }}
                                label={"Solicitante"}
                                id={"nome"}
                                formik={formikSolicitante}
                            />
                            <GABDataTable
                                iconMenu={'akar-icons:arrow-up-right'}
                                onClickMenu={async (event, dataIndex) => {
                                    setModalSelectOpen(false);
                                    await prepareSubmit(solicitantes[dataIndex]);
                                }}
                                rowsPerPage={5}
                                search={false}
                                data={solicitantes} title={'Pessoas'} columns={
                                [
                                    {
                                        name: "nome",
                                        label: "Nome",
                                    },
                                    {
                                        name: "",
                                        label: "",
                                    }
                                ]
                            }/>

                        </Grid>
                    </form>
                </GABModal>
                </form>
            </div>
        </Fade>
    );
};
export default DemandaExport;
