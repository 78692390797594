import React, {useContext} from "react";
import {Badge, Grid, Tooltip, Typography} from "@mui/material";
import GABButtonIcon from "../gab-button-icon";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {useTheme} from "@mui/material/styles";
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";
import {useNavigate} from "react-router-dom";
import {useCopyToClipboard} from "usehooks-ts";
import useResponsive from "../../hooks/useResponsive";


interface propsGABLabel {
    title: string;
    titleBadge?: string | number;
    titleTips?: string;
    titleCopy?:boolean;
    titleVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    subTitle?: string | undefined;
    subTitleBadge?: string | number;
    subTitleTips?: string;
    subTitleCopy?:boolean;
    subTitleVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    size?: number;
    flip?: boolean;
    colorBadge?: 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
    textAlign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
    sizeMobile?:number;

}

const GABLabel: React.FC<propsGABLabel> = (props) => {
    const flipMe = props.flip || false;
    const [value, copy] = useCopyToClipboard()
    const isDesktop = useResponsive("up", "lg");
    const theme = useTheme();
    const {
        addMessage,
    }: propsAppProviderContext = useContext(AppProviderContext);
    const getLabel = (title?:string, titleTips?:string, titleBadge?:any, variant?:any, copyButton?:boolean) =>{
        return(
            <Grid item xs={12} >
                <Tooltip title={titleTips}>
                    <Badge badgeContent={titleBadge} color={props.colorBadge || "primary"} showZero max={99}>
                        {
                            copyButton? <GABButtonIcon
                                tip={`Copiar ${title}`}
                                onClick={
                                    async  ()=>{
                                        copy(title || '')
                                        addMessage(`${title} copiado`, "success")
                                    }
                                }
                                textColor={theme.palette.text.primary}
                                icon={'akar-icons:copy'} size={16} color={'transparent'}/> : <></>
                        }

                        <Typography
                            style={{
                                marginRight: '0.5rem'
                            }}
                            variant={variant}>{title}</Typography>
                    </Badge>
                </Tooltip>
            </Grid>
        )
    }

    return (
        <Grid item container
              md={props?.size || 12}
              xs={props?.sizeMobile || 6}
              style={{
            padding: '0.5rem',
            width: '100%',
            textAlign: props?.textAlign,
        }}>
            {
                props.title?
                getLabel(props.title,props.titleTips, props.titleBadge, props.titleVariant? props.titleVariant : (flipMe ? "subtitle2" : "caption"), props.titleCopy)
                    :<></>
            }
            {
                props.subTitle?
                getLabel(props.subTitle,props.subTitleTips, props.subTitleBadge, props.subTitleVariant? props.subTitleVariant : flipMe ? "caption" : "subtitle2", props.subTitleCopy)
                    :<></>
            }
        </Grid>
    );
};

export default GABLabel;
