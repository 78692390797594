import React, {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {
    Avatar,
    Grid,
    IconButton, LinearProgress, List,
    ListItem,
    ListItemAvatar,
    ListItemText, Typography
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import GABIcon from "../gab-icon";
import GABMenu from "../gab-menu";
import GABMenuItem from "../gab-menu/item";
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";
interface propsCardForm {
    onSelectFile: (file:File, result:any, files: FileUpload[]) => void;
    deleteFile:(file:string)=>void;
    anexos?:any;
}
export type FileUpload = {
    file:File;
    total:number;
    loaded:number;
    error?:boolean;

}

const GABUpload: React.FC<propsCardForm> = (props) => {
    const {
        addMessage,
    }: propsAppProviderContext = useContext(AppProviderContext);
    const uploadRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<FileUpload[]>([]);
    const [file, setFile] = useState('');
    const [fileSelected, setFileSelected] = useState<any>();
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const [imageUpload, setimageUpload] = useState('');
    const listaImages = ['/static/illustrations/upload_rafa.svg', '/static/illustrations/upload_joao.svg']
    const indice = Math.floor(Math.random() * 2 );

    useEffect(()=>{    setimageUpload(listaImages[indice]);},[])

    const updateFile =(fileUpload:FileUpload) =>{
        const newFiles:FileUpload[] = files.filter((f)=>f.file !== fileUpload.file);
        setFiles([
            fileUpload,
            ...newFiles
        ]);
    }
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const uploadFileService = async (file:File)=>{
        if(file) {
            const reader = new FileReader();
            reader.onabort = () => {
                addMessage("Envio de arquivo cancelado", "error");
                updateFile({
                    file : file,
                    total: file.size,
                    loaded: file.size,
                    error: true
                });
            }
            reader.onerror = () => {
                addMessage("Falha no envio de arquivo", "error");
                updateFile({
                    file : file,
                    total: file.size,
                    loaded: file.size,
                    error: true
                });

            }
            reader.onloadend = ()=>{
                updateFile({
                    file : file,
                    total: file.size,
                    loaded: file.size
                });
            }

            reader.onprogress = (a) =>{
                updateFile({
                    file : file,
                    total: a.total,
                    loaded: a.loaded * 100 /  a.total
                });

            }
            reader.onload = async () => {

                try{
                    if(props.onSelectFile){
                        await props.onSelectFile(file,reader.result, files);
                        addMessage("Arquivo enviado com sucesso", "success");
                    }
                }catch (e) {
                    addMessage("Falha no envio de arquivo", "error");
                    updateFile({
                        file : file,
                        total: file.size,
                        loaded: file.size,
                        error: true
                    });
                }
            }
            await reader.readAsBinaryString(file);
        }
    }
    const handleFileChange =async  (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0] ) {
            const fileToUpload = e.target.files[0];
            const result = files.filter( (f)=>{ return f.file.name === fileToUpload.name});
            if(result.length>0){
                return;
            }
            const newFiles:FileUpload[] = [...files];
            newFiles.push({
                file : fileToUpload,
                loaded : 0,
                total: fileToUpload.size
            })
            // setFiles(newFiles);
            setFile('');
            setFileSelected(fileToUpload)
            if(fileToUpload.type.indexOf("image")>-1){
                setFile(URL.createObjectURL(fileToUpload));
            }
            await uploadFileService(fileToUpload);
        }
    };

    const formatSize = (bytes:any) =>{
        if (bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
            return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${' '}${sizes[i]}`;
        }
        return 'n/a';
    }

    const viewFile = (file:File) =>{
        setFile('');
        if(file.type.indexOf("image")>-1){
            setFile(URL.createObjectURL(file));
        }

    }
    const viewFileCaminho = (caminho:string) =>{
            setFile(`'http://134.209.78.93:8080/${caminho}'`);

    }
    // const handleClickAnexo = (event: any, caminho:string) => {
    //     setAnchorEl(event.currentTarget);
    //     setFileSelected({caminho:caminho});
    // };
    const handleClickAnexo = (event: any, file:File) => {
        setAnchorEl(event.currentTarget);
        setFileSelected(file);
    };

    const openFile = (file:any) =>{
        if(file.caminho){
            window.open(`http://134.209.78.93:8080/${file.caminho}`, '_new')
        }else{
            window.open(URL.createObjectURL(file) || '');
        }

    }
    const getIcon = (fileUpload?:FileUpload) =>{
        let typeFile = 'akar-icons:file';
        if(fileUpload){
            if(fileUpload!.file?.type.indexOf("pdf")>-1){
                typeFile = 'teenyicons:pdf-outline';
            }
            if(fileUpload!.file?.type.indexOf("image")>-1){
                typeFile = 'akar-icons:image';
            }

            if(fileUpload!.error){
                typeFile = 'ic:sharp-error-outline';
            }
        }

        return (
            <Avatar>
                <GABIcon icon={typeFile}/>
            </Avatar>
        )
    }

    const getIconFile = (fileUpload?:string) =>{
        let typeFile = 'akar-icons:file';
        if(fileUpload){
            if(fileUpload.endsWith("pdf")){
                typeFile = 'teenyicons:pdf-outline';
            }
            if(fileUpload.endsWith(".png") || fileUpload.endsWith(".jpg") || fileUpload.endsWith(".jpeg")){
                typeFile = 'akar-icons:image';
            }
        }

        return (
            <Avatar>
                <GABIcon icon={typeFile}/>
            </Avatar>
        )
    }

    return (
        <Grid container spacing={1} padding={0}>
            <GABMenu  id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
                <GABMenuItem onClick={()=>{
                    if(fileSelected) {
                        props.deleteFile(fileSelected)
                    }
                }} label={"Excluir"} icon={"akar-icons:trash-can"} tooltip={"Excluir arquivo"}/>
                <GABMenuItem
                    onClick={()=>{
                    if(fileSelected) {
                        openFile(fileSelected)
                    }
                    }} label={"Abrir"} icon={"majesticons:open-line"} tooltip={"Abrir arquivo"}/>
            </GABMenu>

            <input
                onChange={handleFileChange}
                ref={uploadRef}
                type={"file"} id={`gab-upload-${indice}`}
                   style={{
                       display : 'none'
                   }}
                   />
            <Grid item xs={6} spacing={1} padding={0} style={{
                display : 'flex',
                flexDirection : 'row',
                justifyContent : 'center',
                padding :'1rem',

            }}>
                <div
                    onClick={()=>{
                        uploadRef.current?.click()
                    }}
                    style={{
                    backgroundColor: `${theme.palette.grey[100]}`,
                    borderStyle : 'dashed',
                    borderWidth :'0.5rem',
                    borderColor: '#DFE3E8',
                    borderRadius :'1rem'
                }}>

                    <div
                    style={{
                        top :'7rem',
                        height : '18.8rem',
                        width : '18.8rem',
                        borderRadius :'1rem',
                        backgroundImage : `url(${file || imageUpload})`,
                        backgroundSize : 'cover'

                    }}
                    />
                    <Typography variant={"h6"}  style={{
                        position :'absolute',
                        top : '23.5rem',
                        textShadow :'1px 1px #ffffff',
                        paddingLeft : '2.5rem'
                    }}>CLIQUE PARA ADICIONAR </Typography>
                </div>
            </Grid>
            <Grid item xs={6} spacing={1} padding={0}>
                <Typography variant={"subtitle1"}  style={{
                    textAlign : 'center',
                    padding: '0.2rem'
                }}>ARQUIVOS ANEXADOS ({files.length})</Typography>

                <List  sx={
                    {
                        overflow: 'auto',
                        width: '100%',
                        maxHeight: 320,
                         }}>
                    {
                        props?.anexos?.map(
                            (a:any, index:number)=>{
                                return ( <ListItem key={a.caminho} secondaryAction={
                                    <div>
                                        <IconButton
                                            onClick={
                                                ()=>{
                                                    viewFileCaminho(a.caminho);
                                                }
                                            }
                                        >
                                            <GABIcon
                                                width={20} height={20} icon={'akar-icons:eye-open'}/>
                                        </IconButton>

                                        <IconButton onClick={
                                            (event: any) => {
                                                handleClickAnexo(event,a)
                                            }
                                        }>
                                            <GABIcon
                                                width={20} height={20}
                                                icon={"iconamoon:menu-kebab-vertical-bold"}
                                            />
                                        </IconButton>
                                    </div>

                                }>
                                    <ListItemAvatar>
                                        {
                                            getIconFile(a.caminho)
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{
                                            color : theme.palette.grey [800]
                                        }}
                                        secondaryTypographyProps={
                                            {
                                                color : theme.palette.grey [800]
                                            }
                                        }
                                        primary={a.nome}
                                     />
                                </ListItem>)
                            }
                        )
                    }
                </List>
            </Grid>
        </Grid>
  );
};

export default GABUpload;
